:root {
  --voting-cards-weight-0: #8b898b;
  --voting-cards-weight-1: #f44336;
  --voting-cards-weight-2: #ffc107;
  --voting-cards-weight-3: #4caf50;
}

.voting-voting_cards {
  $rectangle-width: 3rem;

  margin-bottom: 1.5rem;
  border-bottom: 2px solid #d9d9d9;

  &.current-choice {
    text-align: center;
    margin-bottom: 0;
    border-bottom: none;

    .vote-card {
      margin-top: 0.5rem;

      .vote-label {
        margin-bottom: 1rem;

        @apply text-black;
      }
    }

    .change-vote-button:hover {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  &.loading {
    opacity: 0.3;
    pointer-events: none;
    position: relative;

    @apply relative cursor-wait;

    &::before {
      @apply content-[""] block w-6 h-6 rounded-full animate-spin border-4 border-l-background border-y-background border-r-secondary;

      z-index: 1000;
      vertical-align: middle;
      position: absolute;
      top: calc(50% - 12.5px);
      left: calc(50% - 12.5px);
    }
  }

  .vote-title {
    text-align: center;
    font-size: 1.5rem;
  }

  .votes_counter {
    letter-spacing: 0.2rem;
    font-size: 1rem;
  }

  .vote-link.hidden {
    display: none;
  }

  .vote-card {
    display: inline-block;

    .vote-label {
      font-size: 1rem;
      margin-bottom: 0;
    }

    > svg {
      display: inline-block;
      width: $rectangle-width;
      height: calc($rectangle-width * 2);
      vertical-align: middle;
      margin: -0.5rem 0 0 0.5rem;
      max-width: 100%;
      transition: transform 150ms ease-in-out;

      &:hover {
        transform: scale(1.15) rotate(-5deg);
      }
    }

    &.weight_3 {
      color: var(--voting-cards-weight-3);

      & > svg {
        fill: var(--voting-cards-weight-3);
      }
    }

    &.weight_2 {
      color: var(--voting-cards-weight-2);

      & > svg {
        fill: var(--voting-cards-weight-2);
      }
    }

    &.weight_1 {
      color: var(--voting-cards-weight-1);

      & > svg {
        fill: var(--voting-cards-weight-1);
      }
    }

    &.voted {
      & > svg {
        transform: scale(1.15) rotate(-5deg);
      }
    }
  }

  .voting-button {
    background: none;
    border: none;
    color: #fff;
    font-weight: bold;
  }

  .dim {
    opacity: 0.3;
    pointer-events: none;
  }

  .disabled {
    pointer-events: none;
  }

  .vote-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;

    .vote-count {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 0.5rem;
    }
  }

  .change-vote-button {
    transition: all 150ms ease-in-out;

    &:hover {
      text-decoration: none;
      font-size: 0.9rem;
      margin-top: 0.1rem;
    }
  }

  .button.abstain-button {
    text-transform: uppercase;
    color: var(--voting-cards-weight-0);
    border-color: var(--voting-cards-weight-0);
    background-color: transparent;

    &.voted {
      background-color: var(--voting-cards-weight-0);
      color: #fff;
      border-color: transparent;

      &.disabled {
        opacity: 1;
      }
    }
  }
}

.vote_proposal_modal {
  div.button__sm {
    display: inline-block;
  }

  .vote-label {
    @apply ml-2;
  }
}

.card__list-metadata {
  .voting-weight_3 {
    color: var(--voting-cards-weight-3);
  }

  .voting-weight_2 {
    color: var(--voting-cards-weight-2);
  }

  .voting-weight_1 {
    color: var(--voting-cards-weight-1);
  }

  .voting-weight_0 {
    color: var(--voting-cards-weight-0);
  }
}
